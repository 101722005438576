import * as React from "react";
import { Select, Row, Button, Modal, Icon, Col, List, Input } from "antd-min";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
import { DragDropContext } from 'react-beautiful-dnd';
import { MainTitle } from "@app/components";
import { SchoolLocale, GSAdminLocale, SurveyLocale } from "@app/locales/localeid";
import { connect, withRouter, StateType, MessageHelper, NotificationType, GLUtil, GLGlobal, GLLocale } from "gl-commonui";
import { SchoolClassModel } from "@app/service/class";
import { getClassesForMoveStudents, getStudentsBy, moveStudent, get, reload } from "@app/states/school/class";
import { StudentSubscriptionType } from "@app/util/enum";
import { PathConfig } from "@app/config/pathconfig";
import { DragDropRegion } from "./components/drag-drop-region";
import { MoveLicence, ArrowClicked } from "./components/move-licence";
import { ClassSelector } from "./components/class-selector";
import { disableFutureLicenseEdit, fmtMsg } from "@app/util";
import { MoveStudentRequestModel } from "@app/states/school/classModel";
import { IRegionService, RegionModel } from '@app/service/admin/regions';
import { ClassHelper, lazyInject, TYPES, fmtMsg, DateHelper, AdjustmentDateFormat } from '../../../util/index';
import { ISchoolLicenseService, UpdateAdjustmentResultCode } from '@app/service/admin/license';
import { ISchoolClassService, SchoolClassPropNames, GetSchoolClassRequest } from '../../../service/class/index';
import { PathConfig as GLPathConfig } from "gl-commonui";
import "./index.less";


const groupByArray = (xs, key) => { return xs.reduce(function (rv, x) { let v = key instanceof Function ? key(x) : x[key]; let el = rv.find((r) => r && r.key === v); if (el) { el.values.push(x); } else { rv.push({ key: v, values: [x] }); } return rv; }, []); }

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

interface MoveStudentPageProps {
    students: any;
    list?: SchoolClassModel[];
    loginInfo: any;
    getClassesForMoveStudents: (d) => void;
    getStudentsBy: (d) => void;
    moveStudent: (d: MoveStudentRequestModel) => void;
}

interface MoveStudentPageState {
    sourceClass: any
    destinationClass: any
    origSourceClass: any,
    origDestinationClass: any,
    sourceClassStudents: any
    destinationClassStudents: any
    orgSourceClassStudents: any
    orgDestinationClassStudents: any
    sourceClassList: any
    destinationClassList: any
    isSourceClassChanged: any,
    defaultSourceClassValue: string,
    studentsMoved: any
    showConfirmModal: any
    noteStudentsMoved: string,
}

@withRouter
@connect(({ schoolClass: { list, students } }: StateType) => ({
    list,
    students,
}), {
    getClassesForMoveStudents,
    getStudentsBy,
    moveStudent,
})
export class MoveStudentPage extends React.Component<RouteComponentProps<any> & MoveStudentPageProps, MoveStudentPageState> {
    @lazyInject(TYPES.IRegionService)
    regionService: IRegionService;

    @lazyInject(TYPES.ISchoolLicenseService)
    licenseService: ISchoolLicenseService;

    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: ISchoolClassService

    id2List = {
        sourceDroppable: 'sourceClassStudents',
        destinationDroppable: 'destinationClassStudents'
    };

    async componentDidMount() {
        const billingInfo = await this.getRegionInfo();
        const licenseInfo = await this.getInvoiceInfo();
        const classInfo = await this.getClassInfo();
        const isLicenseEditDisabled = disableFutureLicenseEdit(billingInfo, classInfo.endDate, licenseInfo ).isLicenseEditDisabled;
        if (isLicenseEditDisabled) {
            this.props.history.push({pathname: GLPathConfig.AccessDenied})
        }

        const { getClassesForMoveStudents, match: { params: { schoolId, campusId, classId } }, getStudentsBy } = this.props;

        getClassesForMoveStudents({ schoolId, campusId });
        getStudentsBy({ schoolId, classId });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.list !== nextProps.list) {
            const { match: { params: { classId } }, list } = nextProps;
            const sourceClassList = [...list];
            const defaultSourceClassValue = sourceClassList.find(x => x.id === classId) ? sourceClassList.find(x => x.id === classId).name : null;
            const sourceClass = sourceClassList.find(x => x.id === classId);
            const destinationClassList = sourceClassList.filter(x => sourceClass && x.id !== sourceClass.id);
            this.setState(
                {
                    sourceClass,
                    origSourceClass: { ...sourceClass },
                    sourceClassList: sourceClassList,
                    isSourceClassChanged: true,
                    destinationClassList: destinationClassList,
                    defaultSourceClassValue,
                });
        }

        if (this.props.students !== nextProps.students) {
            const studentsMoved = [{
                schoolClassId: this.state.sourceClass && this.state.sourceClass.id,
                studentIds: []

            }, {
                schoolClassId: this.state.destinationClass && this.state.destinationClass.id,
                studentIds: []
            }]
            if (this.state.isSourceClassChanged) {
                this.setState({
                    sourceClassStudents: nextProps.students,
                    orgSourceClassStudents: nextProps.students,
                    studentsMoved
                });
            }
            else if (!this.state.isSourceClassChanged && this.state.destinationClass) {
                this.setState({
                    destinationClassStudents: nextProps.students,
                    orgDestinationClassStudents: nextProps.students,
                    studentsMoved
                });
            }
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            sourceClass: null,
            destinationClass: null,
            sourceClassStudents: [],
            destinationClassStudents: [],
            sourceClassList: [],
            destinationClassList: [],
            isSourceClassChanged: true,
            defaultSourceClassValue: null,
            studentsMoved: [],
            showConfirmModal: false,
            origSourceClass: null,
            origDestinationClass: null,
            orgSourceClassStudents: null,
            orgDestinationClassStudents: null,
            noteStudentsMoved: null,
        }
        this.onClassChange = this.onClassChange.bind(this);
        this.checkIfChangesDone = this.checkIfChangesDone.bind(this);
        this.onConfirmMoveStudent = this.onConfirmMoveStudent.bind(this);
        this.onSelected = this.onSelected.bind(this);
    }

    getClassInfo = async () => {
        const { match: { params: { regionId, schoolId, classId } } } = this.props;
        let schoolClassInfo = null;
        await this.schoolClassService.get({ schoolId, id: classId }).then(res => {
            schoolClassInfo = res;
        });
        return schoolClassInfo;
    }

    getRegionInfo = async () => {
        const { match: { params: { regionId } } } = this.props;
        let regionInfo = null;
        await this.regionService.getRegionBillingInfo({ id: regionId }).then(res => {
            regionInfo = res;
        });
        return regionInfo;
    }

    getInvoiceInfo = async () => {
        const { match: { params: { regionId, schoolId, classId } } } = this.props;
        let licenseInfo = null;
        await this.licenseService.getClassInvoiceInfo({ regionId, schoolId, classId })
            .then(classInvoiceInfo => {
                let keys = ["currentCycle", "lastCycle", "nextCycle"];
                for (let k in keys) {
                    classInvoiceInfo[keys[k]].billingCycleStart = classInvoiceInfo[keys[k]].billingCycleStart
                        ? classInvoiceInfo[keys[k]].billingCycleStart.substring(0, 10)
                        : null;
                    classInvoiceInfo[keys[k]].billingCycleEnd = classInvoiceInfo[keys[k]].billingCycleEnd
                        ? classInvoiceInfo[keys[k]].billingCycleEnd.substring(0, 10)
                        : null;
                }
                if (classInvoiceInfo) {
                    classInvoiceInfo.currentLicensePeriodStart = classInvoiceInfo.currentLicensePeriodStart.substring(0, 10);
                    classInvoiceInfo.currentLicensePeriodEnd = classInvoiceInfo.currentLicensePeriodEnd.substring(0, 10);
                }
                if(classInvoiceInfo.currentCycleInvoiceDate) {
                    classInvoiceInfo.currentCycleInvoiceDate = classInvoiceInfo.currentCycleInvoiceDate.substring(0,10);
                }
                licenseInfo = classInvoiceInfo;
            });
            return licenseInfo;
    }

    renderOption(campusGrp, campusIndex) {
        let classOptionList = campusGrp.values.map((item, index) => {
            return <Select.Option key={index} value={item.id} title={item.name}>{item.name}</Select.Option>
        });
        return <Select.OptGroup key={campusIndex} label={campusGrp.key}>
            {classOptionList}
        </Select.OptGroup>
    }

    getList = id => this.state[this.id2List[id]];

    onSelected(isSourceClass, classId) {
        if (this.checkIfChangesDone()) {
            let that = this;
            const modal = Modal.confirm(
                {
                    content: GLGlobal.intl.formatMessage({ id: SchoolLocale.ChangeClassMessage }),
                    onOk() {

                        that.onClassChange(isSourceClass, classId, true);
                    },
                    onCancel() {
                        modal && modal.destroy();
                    }
                }
            );
        }
        else {
            this.onClassChange(isSourceClass, classId, false);
        }
    }

    onResetClick = () => {
        const { origDestinationClass, origSourceClass, orgSourceClassStudents, orgDestinationClassStudents } = this.state;
        const sourceClassList = [...this.state.sourceClassList];
        // reset source class
        let sourceIndex = sourceClassList.findIndex(x => x.id == origSourceClass.id);
        let destIndex = sourceClassList.findIndex(x => x.id == origDestinationClass.id);
        sourceClassList.splice(sourceIndex, 1, { ...origSourceClass });
        sourceClassList.splice(destIndex, 1, { ...origDestinationClass });
        // reset destination class
        let destinationClassList = [...sourceClassList.filter(x => x.id !== origSourceClass.id)];
        const studentsMoved = [{
            schoolClassId: this.state.sourceClass && this.state.sourceClass.id,
            studentIds: []

        }, {
            schoolClassId: this.state.destinationClass && this.state.destinationClass.id,
            studentIds: []
        }]
        this.setState({
            sourceClassList,
            destinationClassList,
            sourceClass: { ...origSourceClass },
            destinationClass: { ...origDestinationClass },
            sourceClassStudents: orgSourceClassStudents,
            destinationClassStudents: orgDestinationClassStudents,
            studentsMoved
        });
    }

    onClassChange(isSourceClass, classId, resetClass) {
        const { match: { params: { schoolId } }, getStudentsBy } = this.props;
        const { studentsMoved, destinationClassStudents, sourceClassStudents,
            origDestinationClass, origSourceClass } = this.state;
        const sourceClassList = [...this.state.sourceClassList];
        getStudentsBy({ schoolId, classId });
        if (isSourceClass) {
            let sourceClass = sourceClassList.find(x => x.id == classId);
            let destinationClass = { ...this.state.destinationClass };
            let destinationClassStudents = [...this.state.destinationClassStudents];

            if (resetClass) {
                let studentsMovedtoSrc = sourceClassStudents.filter(stu => studentsMoved[0].studentIds.includes(stu.id));
                let destClassStudents = destinationClassStudents.filter(stu => !studentsMoved[1].studentIds.includes(stu.id));
                destinationClassStudents = [...destClassStudents, ...studentsMovedtoSrc];
                destinationClass = { ...origDestinationClass };
                let sourceIndex = sourceClassList.findIndex(x => x.id == origSourceClass.id);
                let destIndex = sourceClassList.findIndex(x => x.id == origDestinationClass.id);
                sourceClassList.splice(sourceIndex, 1, { ...origSourceClass });
                sourceClassList.splice(destIndex, 1, { ...origDestinationClass });
            }
            if (this.state.sourceClass.licenseType !== sourceClass.licenseType || sourceClass.id == destinationClass.id) {
                destinationClass = null;
                destinationClassStudents = [];
            }
            let destinationClassList = [...sourceClassList.filter(x => x.id !== sourceClass.id)];

            this.setState({
                isSourceClassChanged: isSourceClass,
                sourceClass: sourceClass,
                origSourceClass: { ...sourceClass },
                origDestinationClass: { ...destinationClass },
                destinationClassList: destinationClassList,
                destinationClass: destinationClass,
                destinationClassStudents: destinationClassStudents,
                sourceClassList: sourceClassList,
            });
        }
        else {

            let updatedSourceClassStudents = [...sourceClassStudents];
            if (resetClass) {
                let studentsMovedtoDest = destinationClassStudents.filter(stu => studentsMoved[1].studentIds.includes(stu.id));
                updatedSourceClassStudents = sourceClassStudents.filter(stu => !studentsMoved[0].studentIds.includes(stu.id));
                updatedSourceClassStudents = [...updatedSourceClassStudents, ...studentsMovedtoDest];
                let sourceIndex = sourceClassList.findIndex(x => x.id == origSourceClass.id);
                let destIndex = sourceClassList.findIndex(x => x.id == origDestinationClass.id);
                sourceClassList.splice(sourceIndex, 1, { ...origSourceClass });
                sourceClassList.splice(destIndex, 1, { ...origDestinationClass });
            }

            let destinationClassList = [...sourceClassList.filter(x => x.id !== this.state.sourceClass.id)];

            this.setState({
                isSourceClassChanged: isSourceClass,
                destinationClass: { ...sourceClassList.find(x => x.id == classId) },
                origDestinationClass: { ...sourceClassList.find(x => x.id == classId) },
                sourceClassStudents: updatedSourceClassStudents,
                destinationClassList: destinationClassList,
                sourceClassList: sourceClassList,
                sourceClass: { ...origSourceClass }
            });
        }
    }

    onDragEnd = result => {
        const { source, destination, draggableId } = result;
        let sourceClass = { ...this.state.sourceClass };
        let destinationClass = { ...this.state.destinationClass };
        let studentsMoved = [...this.state.studentsMoved];
        let sourceClassList = [...this.state.sourceClassList];
        let destinationClassList = [...this.state.destinationClassList];

        if (!this.state.sourceClass || !this.state.destinationClass || Object.keys(this.state.destinationClass).length === 0) {
            return;
        }
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            return;
        } else {
            let draggedStudent = this.getList(source.droppableId).find(x => x.id === draggableId);
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            if (source.droppableId == "sourceDroppable") {
                if (draggedStudent.subscriptionType === StudentSubscriptionType.Digital) {
                    sourceClass.digitalLicense -= 1;
                    destinationClass.digitalLicense += 1;
                }
                else if (draggedStudent.subscriptionType === StudentSubscriptionType.TextBook) {
                    sourceClass.textbookLicense -= 1;
                    destinationClass.textbookLicense += 1;
                }
                else if (draggedStudent.subscriptionType === StudentSubscriptionType.Dual) {
                    sourceClass.textbookLicense -= 1;
                    sourceClass.digitalLicense -= 1;
                    destinationClass.textbookLicense += 1;
                    destinationClass.digitalLicense += 1;
                }
                else {
                    return;
                }
                sourceClass.studentCount -= 1;
                destinationClass.studentCount += 1;
                let index = studentsMoved[0].studentIds.indexOf(draggableId);
                if (index > -1) {
                    studentsMoved[0].studentIds.splice(index, 1);
                }
                else {
                    studentsMoved[1].studentIds.push(draggableId);
                }
            }
            else {
                if (draggedStudent.subscriptionType === StudentSubscriptionType.Digital) {
                    destinationClass.digitalLicense -= 1;
                    sourceClass.digitalLicense += 1;
                }
                else if (draggedStudent.subscriptionType === StudentSubscriptionType.TextBook) {
                    destinationClass.textbookLicense -= 1;
                    sourceClass.textbookLicense += 1;
                }
                else if (draggedStudent.subscriptionType === StudentSubscriptionType.Dual) {
                    destinationClass.textbookLicense -= 1;
                    destinationClass.digitalLicense -= 1;
                    sourceClass.textbookLicense += 1;
                    sourceClass.digitalLicense += 1;
                }
                else {
                    return;
                }
                sourceClass.studentCount += 1;
                destinationClass.studentCount -= 1;
                let index = studentsMoved[1].studentIds.indexOf(draggableId);
                if (index > -1) {
                    studentsMoved[1].studentIds.splice(index, 1);
                }
                else {
                    studentsMoved[0].studentIds.push(draggableId);
                }
            }
            let sourceIndex = sourceClassList.findIndex(x => x.id == sourceClass.id);
            let destIndex = sourceClassList.findIndex(x => x.id == destinationClass.id);
            sourceClassList.splice(sourceIndex, 1, sourceClass);
            sourceClassList.splice(destIndex, 1, destinationClass);
            destinationClassList = [...sourceClassList.filter(x => x.id !== sourceClass.id)];

            this.setState({
                sourceClassList,
                destinationClassList,
                sourceClassStudents: result.sourceDroppable,
                destinationClassStudents: result.destinationDroppable,
                sourceClass,
                destinationClass,
                studentsMoved
            });
        }
    }

    onConfirmMoveStudent() {
        this.setState({ showConfirmModal: false });
        const { studentsMoved, sourceClass, destinationClass, sourceClassStudents, destinationClassStudents, orgSourceClassStudents, origSourceClass, noteStudentsMoved } = this.state;
        const { moveStudent } = this.props;
        // create the required model
        const studentMoveModel = [
            {
                sourceClassId: sourceClass.id,
                targetClassId: destinationClass.id,
                movedStudents: studentsMoved[1].studentIds
            },
            {
                sourceClassId: destinationClass.id,
                targetClassId: sourceClass.id,
                movedStudents: studentsMoved[0].studentIds
            },
        ];

        const origUnregisteredSource = origSourceClass.studentCount - orgSourceClassStudents.length;
        const unregisteredSource = sourceClass.studentCount - sourceClassStudents.length;
        const sourceId = origUnregisteredSource > unregisteredSource ? sourceClass.id : destinationClass.id;
        const licenseMoveModel = {
            sourceId: sourceId,
            targetId: sourceId === sourceClass.id ? destinationClass.id : sourceClass.id,
            unregisteredStudents: Math.abs(origUnregisteredSource - unregisteredSource)
        }

        const moveStudentNote = noteStudentsMoved;

        moveStudent({ studentMoveModel, licenseMoveModel, moveStudentNote });

        let resetStudentsMoved: any = Array.from(studentsMoved);
        resetStudentsMoved.forEach(item => item.studentIds = []);
        this.setState(
            {
                studentsMoved: resetStudentsMoved,
                origSourceClass: { ...sourceClass },
                origDestinationClass: { ...destinationClass },
                orgSourceClassStudents: sourceClassStudents,
                orgDestinationClassStudents: destinationClassStudents
            });
    }

    confirmMoveStudentModal() {
        const { sourceClass, destinationClass, sourceClassStudents, destinationClassStudents, studentsMoved, origSourceClass, orgSourceClassStudents } = this.state;
        if (this.checkIfChangesDone()) {
            const wrapList = (items: JSX.Element[]) => (items.map((item, index) => <li key={index}>{item}</li>));
            const wrapListItem = (item: JSX.Element, index: number) => (<li key={index}>{item}</li>);
            let sourceStudentNames = sourceClassStudents.filter(stu => studentsMoved[0].studentIds.indexOf(stu.id) > -1).map((stu, index) => wrapListItem(stu.name, index));
            let destStudentNames = destinationClassStudents.filter(stu => studentsMoved[1].studentIds.indexOf(stu.id) > -1).map((stu, index) => wrapListItem(stu.name, index));
            const licenceMoved = (origSourceClass.studentCount - orgSourceClassStudents.length) - (sourceClass.studentCount - sourceClassStudents.length);
            const UnregisteredMovedFrom = licenceMoved < 0 ? destinationClass.name : sourceClass.name;
            const movedFromText = GLGlobal.intl.formatMessage({ id: SchoolLocale.MoveStudentSubmit });
            const unregisteredText = fmtMsg({ id: SchoolLocale.MoveStudentUnregisteredInfo }, { 0: Math.abs(licenceMoved) })
            if(!Array.isArray(destStudentNames)) {
                destStudentNames = [];
            }
            if(!Array.isArray(sourceStudentNames)) {
                sourceStudentNames = [];
            }
            Math.abs(licenceMoved) > 0 &&
                (UnregisteredMovedFrom === sourceClass.name
                    ? destStudentNames.unshift(unregisteredText)
                    : sourceStudentNames.unshift(unregisteredText));
            const content = <div className="student-moved-status">
                {((studentsMoved[0].studentIds.length > 0 || studentsMoved[1].studentIds.length > 0) || licenceMoved !== 0) &&
                    <>
                        {destStudentNames && destStudentNames.length > 0 && (
                            <div className="item">
                                <span className="direction">
                                    {movedFromText}
                                    <span className="item__class"> {"("}{sourceClass.name}</span>
                                    <Icon type="arrow-right"></Icon>
                                    <span className="item__class">{destinationClass.name}{")"}</span>
                                </span>
                                <ul className="item__list"> {wrapList(destStudentNames)} </ul>
                            </div>
                        )}
                        {sourceStudentNames && sourceStudentNames.length > 0 && (
                            <div className="item">
                                <span className="direction">
                                    {movedFromText}
                                    <span className="item__class"> {"("}{destinationClass.name}</span>
                                    <Icon type="arrow-right"></Icon>
                                    <span className="item__class">{sourceClass.name}{")"}</span>
                                </span>
                                <ul className="item__list"> {wrapList(sourceStudentNames)} </ul>
                            </div>
                        )}
                    </>
                }
            </div>;
            let that = this;
            const modal = Modal.confirm({
                title: fmtMsg({ id: SchoolLocale.MoveStudentSaveMessage }),
                width: 600,
                content: content,
                onOk() {
                    that.onConfirmMoveStudent();
                    that.setState({ noteStudentsMoved: null });
                },
                okText: fmtMsg({ id: SchoolLocale.MoveStudentConfirmButtonOk }),
                onCancel() {
                    modal && modal.destroy();
                }
            });
        }
        else {
            MessageHelper.Message(NotificationType.Warning, GLGlobal.intl.formatMessage({ id: SchoolLocale.NoStudentMessage }));
        }
    }

    onCancelClick() {
        const { match: { params: { classId, campusId, schoolId, regionId } }, history } = this.props;
        if (this.checkIfChangesDone()) {
            const modal = Modal.confirm(
                {
                    content: GLGlobal.intl.formatMessage({ id: SchoolLocale.MoveStudentCancel }),
                    onOk() {
                        history.push({ pathname: GLUtil.pathStringify(PathConfig.ClassLicense, { regionId, schoolId, campusId, classId }) });
                    },
                    onCancel() {
                        modal && modal.destroy();
                    }
                });
        }
        else {
            history.push({ pathname: GLUtil.pathStringify(PathConfig.ClassLicense, { regionId, schoolId, campusId, classId }) });
        }
    }

    checkIfChangesDone(): Boolean {
        const { sourceClass, destinationClass, studentsMoved, origSourceClass } = this.state;
        return (sourceClass && destinationClass && (studentsMoved[0].studentIds.length > 0 || studentsMoved[1].studentIds.length > 0))
            || origSourceClass.studentCount !== sourceClass.studentCount
    }

    licenceCountChange = (sourceStudents: number, destinationStudents: number, arrowClicked: ArrowClicked) => {
        let { sourceClass, destinationClass } = this.state;
        // Below logic is only valid if there remaining studentCount (Licences) > 0
        if (sourceClass.digitalLicense === sourceClass.textbookLicense) {
            // means dual class
            if (arrowClicked === ArrowClicked.back) {
                sourceClass.digitalLicense += 1;
                sourceClass.textbookLicense += 1;
                destinationClass.digitalLicense -= 1;
                destinationClass.textbookLicense -= 1;
            }
            else {
                sourceClass.digitalLicense -= 1;
                sourceClass.textbookLicense -= 1;
                destinationClass.digitalLicense += 1;
                destinationClass.textbookLicense += 1;
            }
        }
        else if (sourceClass.digitalLicense > sourceClass.textbookLicense) {
            // means digital
            if (arrowClicked === ArrowClicked.back) {
                sourceClass.digitalLicense += 1;
                destinationClass.digitalLicense -= 1;
            }
            else {
                sourceClass.digitalLicense -= 1;
                destinationClass.digitalLicense += 1;
            }
        }
        else if (sourceClass.digitalLicense < sourceClass.textbookLicense) {
            // means textbook
            if (arrowClicked === ArrowClicked.back) {
                sourceClass.textbookLicense += 1;
                destinationClass.textbookLicense -= 1;
            }
            else {
                sourceClass.textbookLicense -= 1;
                destinationClass.textbookLicense += 1;
            }
        }
        this.setState({
            sourceClass: Object.assign({}, { ...sourceClass }, { studentCount: sourceStudents }),
            destinationClass: Object.assign({}, { ...destinationClass }, { studentCount: destinationStudents })
        })
    }

    handleNote = (e) => {
        this.setState({
            noteStudentsMoved: e.target.value
        });
    }

    render() {
        const { sourceClassList, destinationClassList, destinationClass, sourceClass, sourceClassStudents,
            destinationClassStudents, defaultSourceClassValue, studentsMoved } = this.state;
        const { match: { params }, students } = this.props;
        const sourceClassOptions = [];
        const destClassOptions = [];
        sourceClassList && sourceClassList.length > 0 && groupByArray(sourceClassList, "campusName").forEach((item, index) => {
            sourceClassOptions.push(this.renderOption(item, index));
        });

        destinationClassList && destinationClassList.length > 0 && groupByArray(destinationClassList, "campusName").forEach((item, index) => {
            destClassOptions.push(this.renderOption(item, index));
        });
        return (
            <div className="move-student-container">
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Row className='content-layout'>
                        <MainTitle title={SchoolLocale.BreadTextMoveStudents} />
                    </Row>
                    <Row justify="end" className="rt">
                        <Col span={3} className="rt__resetText" onClick={this.onResetClick}>
                            <FormattedMessage id={SurveyLocale.SurveyEmailQuestionReset} />
                        </Col>
                    </Row>
                    <Row type="flex" gutter={25} justify="space-between" className="mostcl">
                        <Col sm={24} md={14} lg={15} xl={16} className="mostcl__class-select mostcl__class-select--resize">
                            <ClassSelector
                                defaultSchoolClassValue={defaultSourceClassValue}
                                isSourceClass={true}
                                schoolClassOptions={sourceClassOptions}
                                onClassDropDownChange={this.onSelected}
                                schoolClass={sourceClass}
                            ></ClassSelector>
                        </Col>
                        <Col sm={24} md={10} lg={9} xl={8} className="mostcl__class-select--resize">
                            <ClassSelector
                                defaultSchoolClassValue={null}
                                isSourceClass={false}
                                schoolClassOptions={destClassOptions}
                                onClassDropDownChange={this.onSelected}
                                schoolClass={destinationClass}
                            ></ClassSelector>
                        </Col>
                    </Row>

                    <MoveLicence
                        isReadonly={!sourceClass || !destinationClass}
                        sourceClass={sourceClass}
                        sourceStudents={sourceClassStudents}
                        destinationClass={destinationClass}
                        destinationStudents={destinationClassStudents}
                        onChange={this.licenceCountChange} />

                    <div className="move-student-dnd-wrapper">
                        <div className="move-student-dnd-registered">
                            <FormattedMessage id={SchoolLocale.MoveStudentsRegisteredStudents}></FormattedMessage>
                        </div>
                        <DragDropRegion
                            schoolClass={sourceClass}
                            schoolClassStudents={sourceClassStudents}
                            droppableId="sourceDroppable"
                            otherStudents={destinationClassStudents} />
                        <DragDropRegion
                            schoolClass={destinationClass}
                            schoolClassStudents={destinationClassStudents}
                            droppableId="destinationDroppable"
                            otherStudents={sourceClassStudents} />

                    </div>
                </DragDropContext>
                <div className="move-student-note">
                    <div className="move-student-note__title">
                        <FormattedMessage id={SchoolLocale.MoveStudentsNote} />
                    </div>
                    <Input.TextArea rows={4} value={this.state.noteStudentsMoved} onChange={this.handleNote.bind(this)} autosize={{minRows: 4, maxRows: 4}} />
                </div>
                <div className="move-student-btn-group">
                    <Button size="small" onClick={this.onCancelClick.bind(this)}>
                        <FormattedMessage id={GLLocale.Cancel} />
                    </Button>
                    <Button type="primary" size="small" onClick={this.confirmMoveStudentModal.bind(this)}>
                        <FormattedMessage id={SchoolLocale.MoveStudentSaveButton} />
                    </Button>
                </div>
            </div>
        )
    }

}
