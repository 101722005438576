import * as React from "react";
import { Select } from "antd-min";

interface ClassSelectorProps {
    isSourceClass: boolean;
    defaultSchoolClassValue: any;
    schoolClass: any;
    schoolClassOptions: any;
    onClassDropDownChange: (isSourceClass: boolean, e: any) => void;
}

export const ClassSelector = (props: ClassSelectorProps) => {
    const { isSourceClass, defaultSchoolClassValue, schoolClass, schoolClassOptions, onClassDropDownChange } = props;
    return <>
        {(!isSourceClass || defaultSchoolClassValue)
            && <Select placeholder="Select Class"
                value={schoolClass ? schoolClass.name : undefined}
                defaultValue={isSourceClass ? defaultSchoolClassValue : undefined}
                size='large'
                onChange={(e) => onClassDropDownChange(isSourceClass, e)}>
                {schoolClassOptions}
            </Select>
        }
    </>
}