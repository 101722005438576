import * as React from "react";
import { GLGlobal } from "gl-commonui";
import { Select, Row, Col } from "antd-min";
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { SchoolLocale } from "../../../../locales/localeid";
import "./drag-drop-region.less";

export class DragDropRegion extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    getDroppableClasses(dragging: boolean, disabled: boolean): string {
        return `student-dnd-droppable ${dragging ? "student-dnd-dragging" : ""} ${disabled ? "disabled" : ""}`;
    }

    getListClasses(dragging: boolean): string {
        return `student-dnd-item ${dragging ? "student-dnd-item-dragging" : ""}`;
    }

    isPresentInOtherStudents = (id: string): boolean => {
        if (this.props.otherStudents && this.props.otherStudents.findIndex((item) => item.id == id) > -1) {
            return true;
        }
        return false;
    }

    render() {
        const { schoolClass, schoolClassStudents, droppableId } = this.props;
        let studentCountText = GLGlobal.intl.formatMessage({ id: SchoolLocale.MoveStudentCount });
        return [<div className="move-student-dnd">
            <div className="class-license-info">
                <div className="item">
                    <span className="name">{studentCountText}</span>
                    <span className="value">{schoolClassStudents && schoolClassStudents.length > 0 ? schoolClassStudents.length : "0"}</span>
                </div>
            </div>
            <div className="class-student-list">
                <Droppable droppableId={droppableId}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            className={this.getDroppableClasses(snapshot.isDraggingOver, !schoolClass)}>
                            {schoolClassStudents && schoolClassStudents.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                    isDragDisabled={this.isPresentInOtherStudents(item.id)}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={this.getListClasses(snapshot.isDragging)}
                                        >
                                            {item.name}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>]
    }
}