import * as React from "react";
import { Row, Col } from "gl-commonui/lib/antd-min";
import "./move-licence.less";
import { Button, Input } from "antd-min";
import { GLGlobal } from "gl-commonui";
import { SchoolLocale } from "@app/locales/localeid";
import { SchoolClassMoveStudentModel } from "@app/service/class";
import { FormattedMessage } from "react-intl";

interface MoveLicenceProps {
    sourceClass: SchoolClassMoveStudentModel;
    sourceStudents: any;
    destinationClass: SchoolClassMoveStudentModel;
    destinationStudents: any;
    isReadonly: boolean;
    onChange: (sourceClassStudents, destClassStudents, arrowClicked: ArrowClicked) => void;
}

export enum ArrowClicked {
    back,
    forward
}

export const MoveLicence = (props: MoveLicenceProps) => {
    const { sourceClass, sourceStudents, destinationClass, destinationStudents, onChange, isReadonly } = props;
    // source student count
    const sourceStudentCount = sourceStudents && sourceStudents.length > 0 ? sourceStudents.length : 0;
    // destination student count
    const destinationStudentCount = destinationStudents && destinationStudents.length > 0 ? destinationStudents.length : 0;
    // Total licence (studentCount of class, which means here is that total licences assigned to class registered + unregistered)
    const sourceLicences = sourceClass && sourceClass.studentCount > 0 ? sourceClass.studentCount : 0;
    const destinationLicences = destinationClass && destinationClass.studentCount > 0 ? destinationClass.studentCount : 0;
    // Remaining licence
    const sourceRemainingLicence = sourceLicences - sourceStudentCount;
    const destinationRemainingLicence = destinationLicences - destinationStudentCount;
    // labels
    const licenseCountText = GLGlobal.intl.formatMessage({ id: SchoolLocale.LicenseCount });

    const backArrowClicked = () => {
        // destination must have remaining count greater than 0
        if (destinationRemainingLicence > 0) {
            onChange(sourceClass.studentCount + 1, destinationClass.studentCount - 1, ArrowClicked.back);
        }
    }

    const forwardArrowClicked = () => {
        // source must have remaining count greater than 0
        if (sourceRemainingLicence > 0) {
            onChange(sourceClass.studentCount - 1, destinationClass.studentCount + 1, ArrowClicked.forward);
        }
    }

    return <>
        <div>
            <Row className="lc" gutter={18}>
                <Col span={16}>
                    <Col span={4}> {licenseCountText} </Col>
                    <Col span={20} className="padding_responsive"> {sourceLicences} </Col>
                </Col>
                <Col span={8}>
                    <Col span={8}>{licenseCountText}</Col>
                    <Col span={16} className="padding_responsive">{destinationLicences}</Col>
                </Col>
            </Row>
        </div>
        <div className="ml">
            <div className="ml__unregistered">
                <FormattedMessage id={SchoolLocale.MoveStudentUnRegisteredStudents}></FormattedMessage>
                </div> 
            <Row justify="space-between" className="ml__wrapper">
                <Col span={8} className="ml__licence">
                    <Input value={sourceRemainingLicence} disabled />
                </Col>
                <Col span={8} className="ml__arrowcol">
                    <Row className="ml__arrow">
                        <Col>
                            <Button onClick={backArrowClicked} className="ml__arrow-button" disabled={isReadonly}>
                                <i className="material-icons">arrow_back</i>
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={forwardArrowClicked} className="ml__arrow-button" disabled={isReadonly}>
                                <i className="material-icons">arrow_forward</i>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={8} className="ml__licence">
                    <Input value={destinationRemainingLicence} disabled />
                </Col>
            </Row>
        </div>
    </>
}